.bg {
  width: 100vw;
  background: url('../assest/banner-bg.jpg') left top no-repeat;
  ;
  background-size: 100% 100%;
  padding-top: 126px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  min-width: 1200px;

  // &::after{
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   left: 0;
  //   height: 4164px;
  //   bottom: 0;
  //   width: 100%;
  //   background: url('../assest/guang.png') no-repeat;
  //   background-size: 100% 100%;
  //   pointer-events: none;
  // }
  .warpContent {
    width: 1200px;
    margin: 0 auto;

    .bannerBox {
      box-sizing: border-box;
      display: flex;
      justify-content: center;

      .banner__left {
        display: flex;
        flex-direction: column;
        justify-content: left;

        .logo {
          width: 150px;
          margin-top: 20px;
          animation: bottomToTop .5s 0s;
        }

        .banner_left1 {
          width: 660px;
          margin-top: 36px;
          animation: bottomToTop .5s .1s;
        }

        .banner_left2 {
          width: 368px;
          height: 43px;
          animation: bottomToTop .5s .2s;
          margin-top: 69px;
        }

        .buttonBox {
          display: flex;
          align-items: center;
          margin-top: 106px;
          animation: bottomToTop .5s .3s;

          &>span {
            cursor: pointer;
            display: block;
            width: 150px;
            height: 43px;
            background: url('../assest/button1.png') no-repeat;
            background-size: cover;

            &:last-of-type {
              margin-left: 30px;
              background: url('../assest/button2.png') no-repeat;
              background-size: cover;
            }
          }
        }
      }

      .banner__right {
        width: 369px;
        height: 599px;
        margin-left: 113px;
        animation: rightToLeft .6s;
      }
    }

    .grayText {
      width: 1525px;
      margin-top: 160px;
    }

    .whatibooming {
      position: relative;
      display: flex;
      justify-content: center;
      // align-items: center;
      align-items: center;
      margin-top: 100px;
      z-index: 1;

      .phone {
        width: 599px;
        height: 462px;
      }

      .desc {
        margin-left: 41px;
        width: 532px;
        height: 314px;
      }

      .phone2 {
        width: 603px;
        height: 446px;
        margin-left: 19px;
      }

      .desc2 {
        width: 532px;
        height: 438px;
      }

      .phone3 {
        width: 547px;
        height: 579px;
      }

      .desc3 {
        width: 532px;
        height: 483px;
        margin-left: 64px;
      }

      .phone4 {
        width: 492px;
        height: 571px;
        margin-left: 89px;
      }

      .desc4 {
        width: 561px;
        height: 483px;
      }
    }

    .desc3 {
      width: 1417px;
    }

    .downDesc {
      width: 1200px;
      margin-top: 140px;
    }

    .downbtn {
      width: 195px;
      margin: 0 auto;
      margin-top: 82px;
      cursor: pointer;
      display: block;
    }
  }
}

.footerWarp {
  width: 100vw;

  // background: #1d173c;
  .footer {
    width: 1200px;
    margin: 0 auto;
    height: 186px;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 75px;
    position: relative;

    label {
      font-weight: bold;
    }

    // span{
    //   font-weight: bold;
    // }
    a {
      color: rgba(255, 255, 255, 0.7);
      text-decoration: none;
      position: relative;

      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        height: 1px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.7);
        ;
      }
    }
  }

  .footerFlex {
    display: flex;
    align-items: end;
    justify-content: space-between;
  }

  .alignLeft {
    text-align: left;
    margin-bottom: 25px;
  }

  .bottom15 {
    margin-bottom: 22px;
  }


  .footerBorder {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 35px;
  }

  .logoTitle {
    font-weight: bold;
    font-size: 24px;
  }

  .ml160 {
    margin-left: 134px;
  }
}

.aboutUs {
  width: 1839px;
  margin-left: 59px;
  position: relative;
  z-index: 1;
}

.other a {
  text-decoration: none;
  opacity: 0.6;
}

.icons1 {
  width: 25px;
  height: 25px;
  margin-bottom: 3px;
}

.icons {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

@keyframes bottomToTop {
  0% {
    transform: translateY(100%);
    opacity: 0
  }

  100% {
    transform: translateY(0);
    opacity: 1
  }
}

@keyframes rightToLeft {
  0% {
    transform: translateX(100%);
    opacity: 0
  }

  100% {
    transform: translateX(0px);
    opacity: 1
  }
}