.content {
  img {
    margin: 0 auto;
    display: block;
  }

  width: 100%;
  background: #010c1d url('./assest/app-bg.png') left top no-repeat;
  background-size: auto;
  // height: 74.01rem;
  text-align: center;

  .content_top {
    padding-top: 0.82rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .iBooming {
      width: 2.7rem;
      animation: bottomToTop .5s 0s;
      margin-bottom: 0.26rem;
    }

    .img2 {
      width: 5.45rem;
      margin: 0.2rem 0 0.45rem 0;
      animation: bottomToTop .5s 0.1s;
    }

    .img3 {
      width: 4.48rem;
      margin-bottom: 0.4rem;
      animation: bottomToTop .5s 0.2s;
    }
  }

  .downBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.5rem;

    &>img {
      width: 2.44rem;
      margin-bottom: 0.9rem;
      animation: bottomToTop .5s 0.3s;
    }

    .buttonBox {
      display: flex;
      align-items: center;
      animation: bottomToTop .5s .4s;

      &>span {
        cursor: pointer;
        display: block;
        width: 2.1rem;
        height: 0.6rem;
        background: url('../assest/button1.png') no-repeat;
        background-size: cover;

        &:last-of-type {
          margin-left: 0.3rem;
          background: url('../assest/button2.png') no-repeat;
          background-size: cover;
        }
      }
    }
  }

  .footer {
    display: flex;
    align-items: start;
    justify-content: space-around;
    height: 1.9rem;
    background: rgba(29, 23, 60, 0.65);
    font-size: 0.25rem;
    color: #fff;
    position: relative;

    span {
      font-weight: bold;
    }

    a {
      color: #fff;
      text-decoration: none;
      display: inline-block;

      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        height: 1px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
      }
    }

    .icons1 {
      width: 0.3rem;
      height: 0.3rem;
      margin-bottom: 0.03rem;
    }

    .icons {
      width: 0.38rem;
      height: 0.36rem;
      margin-left: 0.1rem;
    }
  }

}

.img4 {
  animation: rightToLeft .6s;
}

@keyframes bottomToTop {
  0% {
    transform: translateY(100%);
    opacity: 0
  }

  100% {
    transform: translateY(0);
    opacity: 1
  }
}

@keyframes rightToLeft {
  0% {
    transform: translateX(100%);
    opacity: 0
  }

  100% {
    transform: translateX(0px);
    opacity: 1
  }
}